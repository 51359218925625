<template>
  <div class="csv-preview">
    <a-button @click="downloadHandle" icon="download">Download</a-button>
    <!-- <button @click="uploadCsv">Load CSV</button> -->
    <a-input v-model="keyword" placeholder="Search this file" icon="search"></a-input>
    <a-virtual-table
      :columns="tableColumns"
      :data-source="tableData"
      size="small"
      :itemSize="38"
      keyProp="index"
      :row-key="
        (record, index) => {
          return index
        }
      "
      :pagination="false"
      :scroll="scrollData"
      :loading="loading"
    ></a-virtual-table>
  </div>
</template>

<script>
import aVirtualTable from 'a-virtual-table'
export default {
  name: 'CSVPreview',
  components: { aVirtualTable },
  data() {
    return {
      tableColumns: [],
      url: null,
      jsonData: [],
      keyword: null,
      loading: false,
    }
  },
  mounted() {
    let url = decodeURIComponent(this.$route.query.url)
    if (!url) return
    this.url = url
    this.loading = true
    fetch(url)
      .then((response) => response.blob())
      .then((file) => {
        if (url.includes('xlsx')) {
          //xlsx文件
          this.loadXlsx(file)
        } else if (url.includes('csv')) {
          //csv文件
          this.loadCsv(file)
        }
        this.loading = false
      })
  },
  computed: {
    scrollData() {
      return this.$route.query.isPundai ? { x: 3000, y: 800 } : { y: 800 }
    },
    tableData() {
      let arr = this.jsonData
      if (this.keyword) {
        arr = arr.filter((item) => {
          let bol = false
          Object.values(item).forEach((elem) => {
            if (
              String(elem).toLocaleLowerCase().includes(String(this.keyword).toLocaleLowerCase())
            ) {
              bol = true
            }
          })
          return bol
        })
      }
      return arr
    },
  },
  methods: {
    createTableInfo() {
      this.keyword = null
      let tableColumns = []
      if (this.jsonData?.length) {
        for (let item in this.jsonData[0]) {
          const data = {
            title: item,
            dataIndex: item,
            key: item,
          }
          tableColumns.push(data)
        }
        this.tableColumns = tableColumns
        this.jsonData = this.jsonData.map((item, index) => {
          item.index = index
          return item
        })
      }
    },
    uploadCsv() {
      // 使用File API加载本地CSV文件
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = '.csv'
      input.addEventListener('change', () => {
        const file = input.files[0]
        this.loadCsv(file)
      })
      input.click()
    },
    loadCsv(file) {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        // 将CSV文件转换为JSON格式
        let { data } = this.$papa.parse(reader.result, {
          header: true, // 使用第一行作为表头
          skipEmptyLines: true, // 跳过空行
        })
        this.jsonData = data
        this.createTableInfo()
      }
    },
    loadXlsx(file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0] // 假设我们只有一个工作表
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        // 处理转换后的JSON数据
        const headers = jsonData[0]
        const body = jsonData.slice(1).map((line) => {
          return headers.reduce((obj, key, i) => ({ ...obj, [key]: line[i] }), {})
        })
        this.jsonData = body
        this.createTableInfo()
      }
      reader.readAsArrayBuffer(file)
    },
    downloadHandle() {
      let name = this.url.split('/')?.slice(-1)?.[0]
      const a = document.createElement('a') //创建一个<a></a>标签
      a.href = this.url // 将流文件写入a标签的href属性值
      a.download = name //设置文件名
      a.style.display = 'none' // 障眼法藏起来a标签
      document.body.appendChild(a) // 将a标签追加到文档对象中
      a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove() // 一次性的，用完就删除a标签
    },
  },
}
</script>

<style scoped>
.csv-preview :deep .erp-table-thead > tr > th,
.csv-preview :deep .erp-table-tbody > tr > td {
  padding: 8px 8px;
}
</style>
